import styled, { keyframes, css } from 'styled-components/macro'

import { cover } from 'polished'

const fadeIn = keyframes`
	from {
		opacity: 0.15;
	}
	to {
		opacity: 0.5;
	}
`

const animatedOverlay = css`
	animation: ${fadeIn} 0.75s ease forwards;
`

export const Overlay = styled.div`
	${cover()}
	background-color: black;
	visibility: ${(props) => (props.isActive ? 'visible' : 'hidden')};
	z-index: 10;
	opacity: ${(props) => (props.isActive ? 0.5 : 0)};
	transition: all 0.4s;
	${(props) => (props.index === 0 ? animatedOverlay : undefined)};
`
export const Wrapper = styled.article`
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	display: flex;
	flex-wrap: nowrap;
	flex-direction: column;
	width: 100%;
	height: 100%;
	background-color: #f1f3f4;
	transition: transform 0.4s;
	box-shadow: ${(props) =>
		!props.isActive ? 'none' : `0 0 10px -5px rgba(0, 0, 0, 0.2)`};
	z-index: 10;
`

export const Header = styled.header`
	box-sizing: border-box;
	display: flex;
	flex: 0 0 64px;
	order: 0;
	align-items: center;
	justify-content: flex-start;
	border-bottom: 1px solid #dadce0;
	background-color: #fff;
	padding: 0 32px;
`

export const Close = styled.img`
	display: block;
	cursor: pointer;
`

export const Content = styled.section`
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	height: 100%;
	padding: 64px;
`
