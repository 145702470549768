import React from 'react'

import { createGlobalStyle } from 'styled-components/macro'
import { normalize } from 'polished'

import {
	Grommet,
	Button,
	Heading,
	Paragraph,
	FormField,
	TextInput,
	TextArea,
	RadioButtonGroup,
	CheckBox,
} from 'grommet'

import { Default } from './theme'

import SheetContent from './components/SheetContent'

const GlobalStyle = createGlobalStyle`${normalize()}`

const App = () => {
	const [active, setActive] = React.useState(0)
	const [completed, setCompleted] = React.useState([])

	const [value, setValue] = React.useState('one')

	const handlePrev = (index) => {
		if (index === 0) {
			handleClose()
			return
		}

		setActive((s) => s - 1)
		setCompleted((s) => s.slice(0, -1))
	}

	const handleNext = (index) => {
		setActive((s) => s + 1)
		setCompleted((s) => [...s, index])
	}

	const handleClose = () => window.parent.postMessage({ close: true }, '*')

	return (
		<>
			<GlobalStyle />
			<Grommet theme={Default} full={true}>
				<SheetContent
					index={0}
					completed={completed}
					onRenderOverlay={true}
					isActive={active === 0}
					onPrev={handlePrev}
					name="First step"
				>
					<Heading level="2" margin={{ top: '0px', bottom: '0px' }}>
						One morning
					</Heading>
					<Paragraph>
						One morning, when Gregor Samsa woke from troubled dreams, he found
						himself transformed in his bed into a horrible vermin. He lay on his
						armour-like back, and if he lifted his head a little he could see
						his brown belly, slightly domed and divided by arches into stiff
						sections
					</Paragraph>
					<Button
						label="Next"
						onClick={() => handleNext(0)}
						style={{ zIndex: 20 }}
					/>
				</SheetContent>

				<SheetContent
					index={1}
					completed={completed}
					onRenderOverlay={completed.includes(0)}
					isActive={active === 1}
					onPrev={handlePrev}
					name="Second step"
				>
					<FormField label="Title">
						<TextInput placeholder="Title" />
					</FormField>
					<FormField label="Description">
						<TextArea placeholder="Description" />
					</FormField>
					<Button label="Next" onClick={() => handleNext(1)} />
				</SheetContent>

				<SheetContent
					index={2}
					completed={completed}
					onRenderOverlay={completed.includes(1)}
					isActive={active === 2}
					onPrev={handlePrev}
					name="Third step"
				>
					<RadioButtonGroup
						name="doc"
						options={['one', 'two', 'three']}
						value={value}
						onChange={(event) => setValue(event.target.value)}
					/>
					<Button label="Next" onClick={() => handleNext(2)} />
				</SheetContent>

				<SheetContent
					index={3}
					completed={completed}
					onRenderOverlay={completed.includes(2)}
					isActive={active === 3}
					onPrev={handlePrev}
					name="Fourth step"
				>
					<CheckBox label="interested?" />
					<CheckBox label="interested?" />
					<CheckBox label="interested?" />
					<Button label="Next" onClick={() => handleNext(3)} />
				</SheetContent>

				<SheetContent
					index={4}
					completed={completed}
					onRenderOverlay={completed.includes(3)}
					isActive={active === 4}
					onPrev={handlePrev}
					name="Fifth step"
				>
					<Button label="Thx" onClick={() => handleClose()} />
				</SheetContent>
			</Grommet>
		</>
	)
}

export default App
