export const Default = {
	global: {
		font: {
			family: "'Roboto', sans-serif",
		},
	},
	heading: {
		weight: 500,
	},
}
