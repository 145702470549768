import React from 'react'
import styled from 'styled-components'

import { Overlay } from './Sheet/StyledSheet'
import Sheet from './Sheet'

const StyledSheetContent = styled.div`
	max-width: 75%;
	width: 100%;
	margin-bottom: 16px;
	padding: 16px 32px;
	background-color: #fff;
	border-radius: 8px;
	border: 1px solid #dadce0;
`

const SheetContent = ({
	index,
	completed,
	onRenderOverlay,
	isActive,
	onPrev,
	name,
	children,
}) => {
	return (
		<>
			<Overlay
				index={index}
				isActive={onRenderOverlay}
				onClick={() => onPrev(index)}
			/>
			<Sheet
				index={index}
				completed={completed}
				isActive={isActive}
				onPrev={onPrev}
				name={name}
			>
				<StyledSheetContent>{children}</StyledSheetContent>
			</Sheet>
		</>
	)
}

export default SheetContent
