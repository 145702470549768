import React from 'react'

import { Wrapper, Header, Close, Content } from './StyledSheet'
import { Heading } from 'grommet'

const Sheet = ({ index, isActive, completed, name, onPrev, children }) => {
	const VIEWPORT = document.body.offsetWidth
	const SCALE = 0.75

	const [styles, setStyles] = React.useState({
		maxWidth: `${VIEWPORT * SCALE}px`,
		transform: `translate(${VIEWPORT}px, 0px)`,
	})

	React.useEffect(() => {
		if (isActive) {
			setStyles((s) => {
				return {
					...s,
					transform: `translate(${VIEWPORT - VIEWPORT * SCALE}px, 0px)`,
				}
			})
		} else if (!isActive && !completed.includes(index)) {
			setStyles((s) => {
				return {
					...s,
					transform: `translate(${VIEWPORT}px, 0px)`,
				}
			})
		} else if (!isActive && completed.includes(index)) {
			setStyles((s) => {
				return {
					...s,
					transform: `translate(0px, 0px)`,
				}
			})
		}
	}, [isActive]) // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<Wrapper style={styles} isActive={isActive}>
			<Header>
				<Close
					src={require('../../assets/close.svg')}
					alt="Close"
					onClick={() => onPrev(index)}
				/>
				<Heading level="2" margin={{ top: '0px', bottom: '0px', left: '32px' }}>
					{name}
				</Heading>
			</Header>
			<Content>{children}</Content>
		</Wrapper>
	)
}

export default Sheet
